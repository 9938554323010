export interface Industry33 {
  code: number;
  name: string;
}

export interface Industry17 {
  name: string;
  children: Industry33[];
}

export const industries: Industry17[] = [
  {
    name: '食品',
    children: [
      { code: 50, name: '水産・農林業' },
      { code: 3050, name: '食料品' },
    ],
  },
  {
    name: 'エネルギー資源',
    children: [
      { code: 1050, name: '鉱業' },
      { code: 3300, name: '石油・石炭製品' },
    ],
  },
  {
    name: '建設・資材',
    children: [
      { code: 2050, name: '建設業' },
      { code: 3550, name: '金属製品' },
      { code: 3400, name: 'ガラス・土石製品' },
    ],
  },
  {
    name: '素材・化学',
    children: [
      { code: 3100, name: '繊維製品' },
      { code: 3150, name: 'パルプ・紙' },
      { code: 3200, name: '化学' },
    ],
  },
  { name: '医薬品', children: [{ code: 3250, name: '医薬品' }] },
  {
    name: '自動車・輸送機',
    children: [
      { code: 3350, name: 'ゴム製品' },
      { code: 3700, name: '輸送用機器' },
    ],
  },
  {
    name: '鉄鋼・非鉄',
    children: [
      { code: 3450, name: '鉄鋼' },
      { code: 3500, name: '非鉄金属' },
    ],
  },
  { name: '機械', children: [{ code: 3600, name: '機械' }] },
  {
    name: '電機・精密',
    children: [
      { code: 3650, name: '電気機器' },
      { code: 3750, name: '精密機器' },
    ],
  },
  {
    name: '情報通信・サービスその他',
    children: [
      { code: 3800, name: 'その他製品' },
      { code: 5250, name: '情報・通信業' },
      { code: 9050, name: 'サービス業' },
    ],
  },
  { name: '電気・ガス', children: [{ code: 4050, name: '電気・ガス業' }] },
  {
    name: '運輸・物流',
    children: [
      { code: 5050, name: '陸運業' },
      { code: 5100, name: '海運業' },
      { code: 5150, name: '空運業' },
      { code: 5200, name: '倉庫・運輸関連業' },
    ],
  },
  { name: '商社・卸売', children: [{ code: 6050, name: '卸売業' }] },
  {
    name: '小売',
    children: [{ code: 6100, name: '小売業' }],
  },
  { name: '銀行', children: [{ code: 7050, name: '銀行業' }] },
  {
    name: '金融（除く銀行）',
    children: [
      { code: 7100, name: '証券、商品先物取引業' },
      { code: 7150, name: '保険業' },
      { code: 7200, name: 'その他金融業' },
    ],
  },
  { name: '不動産', children: [{ code: 8050, name: '不動産業' }] },
  { name: 'その他', children: [{ code: 9999, name: 'その他' }] },
];

export const industryCodeMap: Record<
  number,
  { industry17: Industry17; industry33: Industry33 }
> = Object.fromEntries(
  industries
    .map((industry) => {
      return industry.children.map((c) => [
        c.code,
        { industry33: c, industry17: industry },
      ]);
    })
    .flat()
);
