import { createTheme, useTheme } from '@mui/material/styles';
import { blue, indigo, orange, red } from '@mui/material/colors';

export const theme = createTheme(
  {
    typography: {
      fontSize: 12,
      h1: {
        fontSize: 32
      },
      h2: {
        fontSize: 28
      }
    },
  }
)

interface Color {
  background: string;
  text: string
}
export interface ObjectColors {
  global: Color
  stock: Color;
  disclosure: Color;
}

export const useColors = (): ObjectColors => {
  const theme = useTheme();
  if (theme.palette.mode === 'light' ) {
    return {
      global: {
        background: indigo[500],
        text: indigo[0]
      },
      stock: {
        background: blue[500],
        text: blue[0]
      },
      disclosure: {
        background: orange[500],
        text: blue[0]
      },
    }
  } else {
    return {
      global: {
        background: indigo[500],
        text: '#fff'
      },
      stock: {
        background: blue[500],
        text: '#fff'
      },
      disclosure: {
        background: orange[500],
        text: '#fff'
      },
    }
  }
}
