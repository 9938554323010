import {
  getInitialFilter,
  PartialCondition,
  UpdatePartial,
  useDisclosureFilterGenerator,
  useInstantFilter,
} from './filter';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FilterCondition } from './condition';
import { useRealtime } from './realtime';
import { useWatchlist } from './watchlist';
import { useTags } from './tags';

export interface FilterData {
  id: number;
  name: string;
  filter: FilterCondition;
  notification: boolean;
}

interface FilterStats {
  dailyCount: number;
}

interface FilterLibraryContext {
  filters: FilterData[];
  filterDict: Record<number, FilterData>;
  updateFilters: (filters: FilterData[]) => void;
  setFilter: (id: number, filter: FilterData) => void;
  deleteFilter: (id: number) => void;
  newFilter: (name: string, filter: FilterCondition) => FilterData;
  filterStats: Record<string, FilterStats>;
  selected?: number;
  setSelected: (id?: number) => void;
  updatePartial: (id: number) => UpdatePartial;
}

const filterContext = createContext<FilterLibraryContext>({
  filters: [],
  filterDict: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFilters: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFilter: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteFilter: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  newFilter: (() => undefined) as unknown as (
    name: string,
    filter: FilterCondition
  ) => FilterData,
  filterStats: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelected: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePartial: () => () => undefined,
});

export const FilterLibraryProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [filters, setFilters] = useState<FilterData[]>([]);
  const [filterStats, setFilterStats] = useState<Record<string, FilterStats>>(
    {}
  );
  const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
  const [selected, setSelected] = useState<number | undefined>();
  const filterGenerator = useDisclosureFilterGenerator();
  const { newReference, removeReference, watchlists } = useWatchlist();
  const instantFilter = useInstantFilter();

  const { children: tagChildren, dict: tagDict } = useTags();

  const { disclosures } = useRealtime();

  useEffect(() => {
    const data = localStorage.getItem('tedinet-filter-library');
    setLocalStorageLoaded(true);
    if (data) {
      const loaded: FilterData[] = JSON.parse(data);
      const existingWatchlistIDs = watchlists.map((w) => w.id);
      for (const k in loaded) {
        for (const watchlistID of loaded[k].filter.stock.watchlists) {
          console.log(k, watchlistID);
          loaded[k].filter.stock.watchlists = loaded[
            k
          ].filter.stock.watchlists.filter((w) =>
            existingWatchlistIDs.includes(w)
          );
        }
        // 2022-06-24
        // Replace v100 with v50 and v50_100
        if ('v100' in loaded[k].filter.stock.marketCapital) {
          if (loaded[k].filter.stock.marketCapital['v100']) {
            loaded[k].filter.stock.marketCapital.v50 = true;
            loaded[k].filter.stock.marketCapital.v50_100 = true;
          } else {
            loaded[k].filter.stock.marketCapital.v50 = false;
            loaded[k].filter.stock.marketCapital.v50_100 = false;
          }
          delete loaded[k].filter.stock.marketCapital['v100'];
        }
        // 2022-06-27
        // Replace v100_1k with v100_300 and v300_1k
        if ('v100_1k' in loaded[k].filter.stock.marketCapital) {
          if (loaded[k].filter.stock.marketCapital['v100_1k']) {
            loaded[k].filter.stock.marketCapital.v100_300 = true;
            loaded[k].filter.stock.marketCapital.v300_1k = true;
          } else {
            loaded[k].filter.stock.marketCapital.v100_300 = false;
            loaded[k].filter.stock.marketCapital.v300_1k = false;
          }
          delete loaded[k].filter.stock.marketCapital['v100_1k'];
        }
      }
      setFilters(loaded);
    } else {
      const defaultFilter = getInitialFilter(tagChildren, false);
      defaultFilter.stock.marketCapital.v50 = true;
      defaultFilter.stock.marketCapital.v50_100 = true;
      defaultFilter.stock.marketCapital.v100_300 = true;
      defaultFilter.stock.marketCapital.v300_1k = true;

      const settlement = tagDict[1];
      const shareholderReturn = tagDict[2];
      defaultFilter.disclosure.tagIDs = [
        ...settlement.children.map((c) => c.id),
        ...shareholderReturn.children.map((c) => c.id),
      ];

      setFilters([
        {
          id: 1,
          name: '決算 & 株主還元',
          filter: defaultFilter,
          notification: false,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (filters.length && localStorageLoaded)
      localStorage.setItem('tedinet-filter-library', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    const disclosureList = Object.values(disclosures);
    if (filters.length && disclosureList?.length) {
      setFilterStats(
        Object.fromEntries(
          filters.map((f) => {
            const filter = filterGenerator(f.filter);
            return [
              f.id,
              {
                dailyCount: filter(disclosureList).length,
              },
            ];
          })
        )
      );
    }
  }, [filters, disclosures]);

  const dict = useMemo(
    () => Object.fromEntries(filters.map((f) => [f.id, f])),
    [filters]
  );

  const updatePartial = (id: number) => (diff: PartialCondition) => {
    const filter = dict[id];
    const originalWatchlists = new Set(filter.filter.stock.watchlists);
    if (diff.stock) {
      Object.assign(filter.filter.stock, diff.stock);
    }
    if (diff.disclosure) {
      Object.assign(filter.filter.disclosure, diff.disclosure);
    }
    if (diff.global) {
      Object.assign(filter.filter.global, diff.global);
    }
    setFilters((prev) => {
      const newWatchlists = new Set(filter.filter.stock.watchlists);
      const added = new Set<number>();

      for (const a of newWatchlists) {
        if (originalWatchlists.has(a)) originalWatchlists.delete(a);
        else added.add(a);
      }
      for (const deleted of originalWatchlists) {
        removeReference(id, deleted);
      }
      newReference(id, Array.from(added));

      return [...prev.filter((f) => f.id !== id), filter].sort(
        (a, b) => a.id - b.id
      );
    });
  };

  return (
    <filterContext.Provider
      value={{
        filters,
        filterDict: dict,
        updateFilters: (filters) => setFilters(filters),
        setFilter: (id, filter) =>
          setFilters((prev) =>
            [...prev.filter((f) => f.id !== id), filter].sort(
              (a, b) => a.id - b.id
            )
          ),
        deleteFilter: (id) => {
          if (selected === id) return;
          dict[id].filter.stock.watchlists.forEach((w) =>
            removeReference(id, w)
          );
          setFilters((prev) => [...prev.filter((f) => f.id !== id)]);
        },
        newFilter: (name: string, filter: FilterCondition) => {
          const id = new Date().getTime();
          const newFilter = {
            id,
            name,
            filter,
            notification: false,
          };
          setFilters((prev) => [...prev, newFilter]);
          newReference(id, filter.stock.watchlists);
          return newFilter;
        },
        filterStats,
        selected,
        setSelected: (id) => setSelected(id),
        updatePartial,
      }}
    >
      {children}
    </filterContext.Provider>
  );
};

export const useFilterLibrary = () => useContext(filterContext);
