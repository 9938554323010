import { DisclosureTagInterface } from '@tedinet/data-access-disclosure';

export type DisclosureTag = DisclosureTagInterface & { parent: DisclosureTag | null, children: DisclosureTag[] };

interface useTagsState {
  list: DisclosureTag[],
  parents: DisclosureTag[],
  children: DisclosureTag[],
  dict: Record<number, DisclosureTag>
}

let state: useTagsState = {
  list: [],
  parents: [],
  children: [],
  dict: {}
}

const tags: DisclosureTagInterface[] = [
  {
    id: 1,
    name: '決算',
    parentID: null
  },
  {
    id: 2,
    name: '株主還元',
    parentID: null
  },
  {
    id: 3,
    name: '月次',
    parentID: null
  },
  {
    id: 4,
    name: '有価証券報告書',
    parentID: null
  },
  {
    id: 5,
    name: '統合報告書',
    parentID: null
  },
  {
    id: 6,
    name: 'CG報告書',
    parentID: null
  },
  {
    id: 7,
    name: '訴訟・行政処分',
    parentID: null
  },
  {
    id: 8,
    name: 'エクイティ',
    parentID: null
  },
  {
    id: 9,
    name: '分割・併合',
    parentID: null
  },
  {
    id: 10,
    name: '人事',
    parentID: null
  },
  {
    id: 11,
    name: '事故・災害',
    parentID: null
  },
  {
    id: 12,
    name: '株主総会',
    parentID: null
  },
  {
    id: 13,
    name: 'IPO',
    parentID: null
  },
  {
    id: 14,
    name: '提携',
    parentID: null
  },
  {
    id: 15,
    name: '英文資料',
    parentID: null
  },
  {
    id: 16,
    name: '上場廃止',
    parentID: null
  },
  {
    id: 17,
    name: '破産・再生',
    parentID: null
  },
  {
    id: 18,
    name: '公開買付け',
    parentID: null
  },
  {
    id: 19,
    name: '定款',
    parentID: null
  },
  {
    id: 20,
    name: 'その他',
    parentID: null
  },
  {
    id: 21,
    name: '決算短信',
    parentID: 1
  },
  {
    id: 22,
    name: '説明会資料',
    parentID: 1
  },
  {
    id: 23,
    name: '経営計画',
    parentID: 1
  },
  {
    id: 24,
    name: '訂正',
    parentID: 1
  },
  {
    id: 25,
    name: '補足資料',
    parentID: 1
  },
  {
    id: 26,
    name: '予想修正',
    parentID: 1
  },
  {
    id: 27,
    name: '配当',
    parentID: 2
  },
  {
    id: 28,
    name: '自社株買い',
    parentID: 2
  },
  {
    id: 29,
    name: '優待',
    parentID: 2
  },
  {
    id: 30,
    name: '訴訟',
    parentID: 7
  },
  {
    id: 31,
    name: '行政処分',
    parentID: 7
  },
  {
    id: 32,
    name: '事故',
    parentID: 11
  },
  {
    id: 33,
    name: '災害',
    parentID: 11
  }
];


export function useTags(): useTagsState {
  if (state.list.length) return state;

  const list: DisclosureTag[] = tags.map(t => ({...t, parent: null, children: []}));
  const dict = Object.fromEntries(list.map(t => [t.id, t]));
  list.forEach(t => {
    if (t.parentID) {
      t.parent = dict[t.parentID];
      t.parent.children.push(t)
    }
  });
  const [parents, children] = list.reduce(([p, c], current) => {
    if (current.parentID || current.children.length === 0) c.push(current);
    if (current.parentID === null) p.push(current);
    return [p, c]
  }, [[], []]);

  state = {
    list,
    dict,
    parents,
    children
  }

  return state;
}
