import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import socketIOClient, { Socket } from 'socket.io-client';

type State = Socket | null;

const initialState = {
  socket: null,
  observables: {},
};

const SocketContext = createContext<State>(null);
const SocketStateContext = createContext<boolean>(false);

export const _useSocket = (): [State, boolean] => {
  const [state, setState] = useState<State>(null);
  const [connectionState, setConnectionState] = useState<boolean>(false);
  const [connecting, setConnecting] = useState(false);
  const [socketConnectionWatcher, setSocketConnectionWatcher] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!state && !connecting && !socketConnectionWatcher) {
      resetSocket();
    }
  }, []);

  const resetSocket = (id?: string) => {
    const i = Math.random().toString().slice(4, 10);
    if (socketConnectionWatcher) clearInterval(socketConnectionWatcher);
    if (state) {
      state.disconnect();
      state.close();
      setConnectionState(false);
    }
    let retryCount = 0;

    const socket = socketIOClient(process.env.NEXT_PUBLIC_API_HOST, {
      transports: ['websocket'],
      query: { a: id, retryCount: 5 },
    });
    socket.on('disconnect', () => {
      console.log('received disconnect');
      socket.connect();
      setConnecting(true);
      setConnectionState(false);
    });
    socket.on('connect', () => {
      console.log('connected');
      setConnecting(true);
      setConnectionState(true);
      retryCount = 0;
    });
    const watcher = setInterval(() => {
      if (!socket.connected && !connecting) {
        console.log(
          `[${i}] detected dead socket connection, retry #`,
          retryCount
        );
        if (retryCount >= 5) {
          console.log(`[${i}] exceeded max socket connection retry count`);
          clearInterval(watcher);
          resetSocket(i);
        }
        setConnecting(true);
        retryCount++;
      }
    }, 1000);
    setSocketConnectionWatcher(watcher);
    setState(socket);
  };

  return [state, connectionState];
};

export function SocketProvider({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element {
  const [socket, socketConnectionState] = _useSocket();

  return (
    <SocketContext.Provider value={socket}>
      <SocketStateContext.Provider value={socketConnectionState}>
        {children}
      </SocketStateContext.Provider>
    </SocketContext.Provider>
  );
}

export const useSocket = () => useContext(SocketContext);
export const useSocketConnectionState = () => useContext(SocketStateContext);
