import { createContext, useContext, useEffect, useState } from 'react';
import {
  FileUpdate,
  FullyJoinedDisclosure,
  FullyJoinedDisclosureVersion,
} from '@tedinet/data-access-disclosure';
import { useSocket } from './socket';
import { fromEvent, map, Observable, Subscription } from 'rxjs';
import { useServerStatus } from './server-status';
import { useAPI } from './use-api';

export type RealtimeContext = {
  disclosures: Record<number, FullyJoinedDisclosure>;
  disclosureObservable?: Observable<FullyJoinedDisclosure[]>;
  initialized: boolean;
};
const RealtimeContext = createContext<RealtimeContext>({
  disclosures: {},
  initialized: false,
});

function _useRealtime(): RealtimeContext {
  const [disclosures, setDisclosures] = useState<
    Record<number, FullyJoinedDisclosure>
  >({});
  const [disclosureObservable, setDisclosureObservable] = useState<
    Observable<FullyJoinedDisclosure[]> | undefined
  >();
  const [connectionObservable, setConnectionObservable] = useState<
    Observable<unknown> | undefined
  >();
  const [onConnectionSubscription, setOnConnectionSubscription] = useState<
    Subscription | undefined
  >();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [pendingVersions, setPendingVersions] = useState<
    Record<number, FullyJoinedDisclosureVersion>
  >({});
  const [pendingFiles, setPendingFiles] = useState<FileUpdate>({});
  const [initialized, setInitialized] = useState(false);
  const serverStatus = useServerStatus();
  const socket = useSocket();
  const { getDisclosures } = useAPI();

  useEffect(() => {
    if (serverStatus?.date)
      getDisclosures({
        date: serverStatus.date,
      }).then(([d]) => {
        console.log(d);
        setDisclosures(
          Object.fromEntries(d.filter((d) => d).map((v) => [v.disclosureID, v]))
        );
        setInitialized(true);
      });
  }, [serverStatus?.date]);

  useEffect(() => {
    console.log('realtime', socket?.connected);
  }, [socket?.connected]);

  useEffect(() => {
    if (socket) {
      subscriptions.forEach((s) => s.unsubscribe());

      const connectionObservable = fromEvent(socket, 'connect');
      setConnectionObservable(connectionObservable);

      const disclosureObservable = fromEvent<FullyJoinedDisclosure[]>(
        socket,
        'new-disclosures'
      ).pipe(
        map((disclosures) => {
          return disclosures.map((d) => ({
            ...d,
            issueCode: d.issueCode.toString(),
          }));
        })
      );
      setDisclosureObservable(disclosureObservable);
      const disclosureSubscription = disclosureObservable.subscribe(
        (u: FullyJoinedDisclosure[]) => {
          setDisclosures((prev) => {
            console.log('setting disclosures');
            for (const d of u) {
              prev[d.disclosureID] = d;
              if (
                pendingVersions[d.disclosureID] &&
                d.latestVersion < pendingVersions[d.disclosureID].version
              ) {
                prev[d.disclosureID].latestDisclosureVersion =
                  pendingVersions[d.disclosureID];
                (prev[d.disclosureID] as any).latestVersion =
                  pendingVersions[d.disclosureID].version;
                delete pendingVersions[d.disclosureID];
              }
            }
            return { ...prev };
          });
        }
      );
      const disclosureVersionSubscription = fromEvent(
        socket,
        'new-versions'
      ).subscribe((u: FullyJoinedDisclosureVersion[]) => {
        setDisclosures((prev) => {
          for (const v of u) {
            if (!prev[v.disclosureID]) {
              setPendingVersions((p) => {
                p[v.disclosureID] = v;
                return { ...p };
              });
            } else if (prev[v.disclosureID].latestVersion < v.version) {
              prev[v.disclosureID].latestDisclosureVersion = v;
              (prev[v.disclosureID] as any).latestVersion = v.version;

              if (pendingFiles[v.disclosureID][v.version]) {
                (prev[v.disclosureID].latestDisclosureVersion as any).files =
                  pendingFiles[v.disclosureID][v.version];
                delete pendingFiles[v.disclosureID][v.version];
                if (
                  Object.keys(pendingFiles[v.disclosureID]).every(
                    (fv) => parseInt(fv) < v.version
                  )
                )
                  delete pendingFiles[v.disclosureID];
              }
            }
          }
          return { ...prev };
        });
      });
      const fileSubscription = fromEvent(socket, 'new-files').subscribe(
        (u: FileUpdate) => {
          setDisclosures((prev) => {
            setPendingFiles((p) => {
              for (const [disclosureID, v] of Object.entries(u)) {
                for (const [version, files] of Object.entries(v)) {
                  if (
                    prev[disclosureID] &&
                    prev[disclosureID].latestVersion > version
                  )
                    continue;
                  if (
                    !prev[disclosureID] ||
                    prev[disclosureID].latestVersion < version
                  ) {
                    if (!(disclosureID in p)) p[disclosureID] = {};
                    p[disclosureID][version] = files;
                  } else
                    prev[disclosureID].latestDisclosureVersion.files = files;
                }
              }
              return { ...p };
            });
            return { ...prev };
          });
        }
      );
      setSubscriptions([
        disclosureSubscription,
        disclosureVersionSubscription,
        fileSubscription,
      ]);
    }
  }, [socket]);

  useEffect(() => {
    if (connectionObservable) {
      if (onConnectionSubscription) onConnectionSubscription.unsubscribe();
      setOnConnectionSubscription(
        connectionObservable.subscribe(() => {
          if (serverStatus?.date)
            getDisclosures({
              date: serverStatus.date,
            }).then(([d]) => {
              setDisclosures(
                Object.fromEntries(
                  d.filter((d) => d).map((v) => [v.disclosureID, v])
                )
              );
            });
        })
      );
    }
  }, [connectionObservable, serverStatus?.date]);

  return {
    disclosures,
    disclosureObservable,
    initialized,
  };
}

export function RealtimeProvider({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element {
  const versions = _useRealtime();

  return (
    <RealtimeContext.Provider value={versions}>
      {children}
    </RealtimeContext.Provider>
  );
}

export const useRealtime = () => useContext(RealtimeContext);
