import {
  DisclosureTagInterface,
  FullyJoinedDisclosure,
  FullyJoinedDisclosureVersion,
  GetDisclosuresRequest,
  GetDisclosuresResponse,
  GetDisclosureTagsResponse,
  GetDisclosureVersionsResponse
} from '@tedinet/data-access-disclosure';
import axios from 'axios';
import {
  GetLastAvailableStockResponse,
  GetStockRequest,
  GetStockResponse,
  StockInterface
} from '@tedinet/data-access-stock';
import { APIServerStatus, GetStatusResponse } from '@tedinet/data-access-app';

const apiHost = process.env.NEXT_PUBLIC_API_HOST;

export async function getDisclosures(
  query?: GetDisclosuresRequest
): Promise<[FullyJoinedDisclosure[], number]> {
  const res = await axios.post<GetDisclosuresResponse>(
    `${apiHost}/api/disclosures`,
    query
  );
  const data = (res.data.data as FullyJoinedDisclosure[]).map((d) => ({
    ...d,
    issueCode: d.issueCode.toString(),
  }));
  return [data, res.data.count];
}

export async function getDisclosureTags(): Promise<DisclosureTagInterface[]> {
  const res = await axios.get<GetDisclosureTagsResponse>(
    `${apiHost}/api/disclosures/tags`
  );
  return res.data.data;
}

export async function getVersions(): Promise<FullyJoinedDisclosureVersion[]> {
  const res = await axios.get<GetDisclosureVersionsResponse>(
    `${apiHost}/api/disclosures/versions`
  );
  return res.data.data;
}

export async function getStocks(
  query?: GetStockRequest
): Promise<StockInterface[]> {
  const queryString = new URLSearchParams();
  if (query?.date) queryString.set('date', query.date);

  const res = await axios.get<GetStockResponse>(
    `${apiHost}/api/stock?${queryString}`
  );
  return (res.data.data as StockInterface[]).map((d) => ({
    ...d,
    issueCode: d.issueCode.toString(),
  }));
}

export async function getLastAvailableStocks(): Promise<
  [StockInterface[], string]
> {
  const res = await axios.get<GetLastAvailableStockResponse>(
    `${apiHost}/api/stock/last-available`
  );
  const stocks = (res.data.data.stocks as StockInterface[]).map((d) => ({
    ...d,
    issueCode: d.issueCode.toString(),
  }));
  return [stocks, res.data.data.date];
}

export async function getStatus(): Promise<APIServerStatus> {
  const res = await axios.get<GetStatusResponse>(`${apiHost}/api/status`);
  return res.data.data;
}
