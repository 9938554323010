import { StockInterface } from './stock';
import { SuccessAPIResponse } from '@tedinet/data-access-app';
import { IsDateString, IsOptional } from 'class-validator';

export class GetStockRequest {
  @IsOptional()
  @IsDateString()
  date?: string;
}
export type GetStockResponse = SuccessAPIResponse<StockInterface[]>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetLastAvailableStockRequest {}
export type GetLastAvailableStockResponse = SuccessAPIResponse<{
  stocks: StockInterface[];
  date: string;
}>;
