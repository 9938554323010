import { createContext, useContext, useEffect, useState } from 'react';
import { UpstreamStatus } from '@tedinet/data-access-app';
import { useSocket } from './socket';
import { fromEvent, Subscription } from 'rxjs';
import { DateUpdatePayload } from '@tedinet/data-access-websocket';
import { UpstreamStatusUpdatePayload } from '@tedinet/data-access-websocket';
import { useAPI } from './use-api';

type ServerStatusState = {
  date: string;
  healthy: boolean;
  upstreamStatus: UpstreamStatus;
  isNetworkError: boolean;
};

const serverStatusContext = createContext(null);

const _useServerStatus = () => {
  const { getStatus } = useAPI();
  const [status, setStatus] = useState<ServerStatusState>({
    date: '',
    healthy: true,
    upstreamStatus: UpstreamStatus.UNKNOWN,
    isNetworkError: false,
  });
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const socket = useSocket();

  useEffect(() => {
    getStatus().then((s) => setStatus((p) => ({ ...p, ...s })));
  }, []);

  useEffect(() => {
    if (socket) {
      subscriptions.forEach((s) => s.unsubscribe());

      socket.on('connect', () =>
        getStatus()
          .then((d) => setStatus((p) => ({ ...p, ...d })))
          .catch((e) =>
            setStatus((p) => ({
              ...p,
              healthy: false,
              upstreamStatus: UpstreamStatus.UNKNOWN,
            }))
          )
      );
      const dateUpdateObservable = fromEvent<DateUpdatePayload>(
        socket,
        'date-update'
      );
      const dateUpdateSubscription = dateUpdateObservable.subscribe((v) =>
        setStatus((p) => ({ ...p, date: v.date }))
      );
      setSubscriptions((p) => [...p, dateUpdateSubscription]);

      const upstreamStatusUpdateObservable =
        fromEvent<UpstreamStatusUpdatePayload>(socket, 'new-upstream-status');
      const upstreamStatusUpdateSubscription =
        upstreamStatusUpdateObservable.subscribe((v) =>
          setStatus((p) => ({ ...p, upstreamStatus: v }))
        );
      setSubscriptions((p) => [...p, upstreamStatusUpdateSubscription]);
    }
  }, [socket]);

  return status;
};

export const ServerStatusProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const status = _useServerStatus();

  return (
    <serverStatusContext.Provider value={status}>
      {children}
    </serverStatusContext.Provider>
  );
};

export const useServerStatus = () =>
  useContext<ServerStatusState>(serverStatusContext);
