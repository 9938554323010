import { AppProps } from 'next/app';
import Head from 'next/head';
import './styles.css';
import { RealtimeProvider } from '../libs/realtime';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '../libs/theme';
import { SocketProvider } from '../libs/socket';
import { InstantFilterProvider } from '../libs/filter';
import { WatchlistProvider } from '../libs/watchlist';
import { ServerStatusProvider } from '../libs/server-status';
import { FilterLibraryProvider } from '../libs/filter-library';
import { NotificationProvider } from '../libs/notification';
import { RangeProvider } from '../libs/range';
import { StockProvider } from '../libs/stock';
import { SnackbarProvider } from 'notistack';
import { UseAPIProvider } from '../libs/use-api';
import 'reflect-metadata';

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Welcome to tedinet-front!</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <SnackbarProvider>
        <UseAPIProvider>
          <SocketProvider>
            <ServerStatusProvider>
              <RangeProvider>
                <StockProvider>
                  <RealtimeProvider>
                    <CssBaseline>
                      <ThemeProvider theme={theme}>
                        <WatchlistProvider>
                          <InstantFilterProvider>
                            <FilterLibraryProvider>
                              <NotificationProvider>
                                <main className="app">
                                  <Component {...pageProps} />
                                </main>
                              </NotificationProvider>
                            </FilterLibraryProvider>
                          </InstantFilterProvider>
                        </WatchlistProvider>
                      </ThemeProvider>
                    </CssBaseline>
                  </RealtimeProvider>
                </StockProvider>
              </RangeProvider>
            </ServerStatusProvider>
          </SocketProvider>
        </UseAPIProvider>
      </SnackbarProvider>
    </>
  );
}

export default CustomApp;
