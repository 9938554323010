import { createContext, useContext, useState } from 'react';
import {
  getDisclosures,
  getDisclosureTags,
  getLastAvailableStocks,
  getStatus,
  getStocks,
  getVersions,
} from './api';
import { useSnackbar } from 'notistack';
import {
  APIServerStatus,
  InstanceRole,
  UpstreamStatus,
} from '@tedinet/data-access-app';
import { StockInterface } from '@tedinet/data-access-stock';
import {
  DisclosureTagInterface,
  FullyJoinedDisclosure,
  FullyJoinedDisclosureVersion,
} from '@tedinet/data-access-disclosure';

export interface UseAPIContext {
  getDisclosures: typeof getDisclosures;
  getDisclosureTags: typeof getDisclosureTags;
  getVersions: typeof getVersions;
  getStocks: typeof getStocks;
  getLastAvailableStocks: typeof getLastAvailableStocks;
  getStatus: typeof getStatus & { isNetworkError?: boolean };
}

const originalAPIs = {
  getDisclosures,
  getDisclosureTags,
  getVersions,
  getStocks,
  getLastAvailableStocks,
  getStatus,
};
const apiContext = createContext<UseAPIContext>(originalAPIs);

const _useAPI = (): UseAPIContext => {
  const { enqueueSnackbar } = useSnackbar();

  const _getDisclosures = (...params: Parameters<typeof getDisclosures>) =>
    getDisclosures(...params).catch((e): [FullyJoinedDisclosure[], number] => {
      console.error(e);
      enqueueSnackbar('開示の取得に失敗しました', { variant: 'error' });
      return [[], 0];
    });
  const _getDisclosureTags = (
    ...params: Parameters<typeof getDisclosureTags>
  ) =>
    getDisclosureTags(...params).catch((e): DisclosureTagInterface[] => {
      console.error(e);
      enqueueSnackbar('開示タグマスターの取得に失敗しました。', {
        variant: 'error',
      });
      return [];
    });
  const _getVersions = (...params: Parameters<typeof getVersions>) =>
    getVersions(...params).catch((e): FullyJoinedDisclosureVersion[] => {
      console.error(e);
      enqueueSnackbar('開示バージョン情報の取得に失敗しました。', {
        variant: 'error',
      });
      return [];
    });
  const _getStocks = (...params: Parameters<typeof getStocks>) =>
    getStocks(...params).catch((e): StockInterface[] => {
      console.error(e);
      enqueueSnackbar('銘柄情報の取得に失敗しました。', { variant: 'error' });
      return [];
    });
  const _getLastAvailableStocks = (
    ...params: Parameters<typeof getLastAvailableStocks>
  ) =>
    getLastAvailableStocks(...params).catch((e): [StockInterface[], string] => {
      console.error(e);
      enqueueSnackbar('最新の銘柄情報の取得に失敗しました。', {
        variant: 'error',
      });
      return [[], ''];
    });
  const _getStatus = (...params: Parameters<typeof getStatus>) =>
    getStatus(...params).catch(
      (e): APIServerStatus & { isNetworkError?: boolean } => {
        console.error(e);
        enqueueSnackbar('サーバ情報の取得に失敗しました。', {
          variant: 'error',
        });
        return {
          healthy: false,
          date: '',
          time: '',
          upstreamStatus: UpstreamStatus.UNKNOWN,
          role: InstanceRole.API,
          isNetworkError: e.request,
        };
      }
    );

  return {
    getDisclosures: _getDisclosures,
    getDisclosureTags: _getDisclosureTags,
    getVersions: _getVersions,
    getStocks: _getStocks,
    getLastAvailableStocks: _getLastAvailableStocks,
    getStatus: _getStatus,
  };
};

export const UseAPIProvider = ({ children }: { children: JSX.Element }) => {
  const apis = _useAPI();
  return <apiContext.Provider value={apis}>{children}</apiContext.Provider>;
};

export const useAPI = () => useContext(apiContext);
