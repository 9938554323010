import { createContext, useContext, useEffect, useState } from 'react';
import { FilterData, useFilterLibrary } from './filter-library';
import { useRealtime } from './realtime';
import { Subscription } from 'rxjs';
import { useDisclosureFilterGenerator } from './filter';
import { FullyJoinedDisclosure } from '@tedinet/data-access-disclosure';
import { useStocks } from './stock';

interface NotificationContext {
  enabled: boolean;
}

const notificationContext = createContext<NotificationContext>({
  enabled: false,
});

export const NotificationProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [enabled, setEnabled] = useState(false);
  const [subscription, setSubscription] = useState<Subscription | undefined>();

  const filterLibrary = useFilterLibrary();
  const { initialized, disclosureObservable } = useRealtime();
  const filterGenerator = useDisclosureFilterGenerator();

  const { dict: stockDict, lastAvailableDict } = useStocks();

  const notifyNewDisclosure = (
    filters: FilterData[],
    disclosures: FullyJoinedDisclosure[]
  ) => {
    const [firstDisclosure, issueCode] = ((): [
      FullyJoinedDisclosure,
      string
    ] => {
      for (const disclosure of disclosures) {
        if (disclosure.issueCode.slice(-1) !== '0') continue;
        return [disclosure, disclosure.issueCode.slice(0, 4)];
      }
      return [disclosures[0], disclosures[0].issueCode];
    })();
    new Notification(
      `${firstDisclosure.latestDisclosureVersion.time.slice(0, -3)}「${
        filters[0].name
      }」${
        (filters.length > 1 && `ほか${filters.length - 1}個の保存済み条件`) ||
        ''
      }に新規開示あり`,
      {
        body: `${issueCode} ${
          (stockDict[issueCode] ?? lastAvailableDict[issueCode])?.name ??
          '(名称取得失敗)'
        } 『${firstDisclosure.latestDisclosureVersion.title}』${
          (disclosures.length > 1 && `ほか${disclosures.length - 1}件`) || ''
        }`,
      }
    );
  };

  useEffect(() => {
    if (initialized && disclosureObservable) {
      // 既にobserveしていたら一度解除
      if (subscription) {
        subscription.unsubscribe();
        setSubscription(undefined);
      }

      const notifyingFilters = filterLibrary.filters.filter(
        (f) => f.notification
      );
      if (notifyingFilters.length === 0) return;

      // 通知の許可を取得
      acquireNotificationPermission();

      const newSubscription = disclosureObservable.subscribe(
        (newDisclosures) => {
          const disclosures = new Set<FullyJoinedDisclosure>();
          const filters = new Set<FilterData>();
          let shouldNotify = false;

          for (const filter of notifyingFilters) {
            const filtered = filterGenerator(filter.filter)(newDisclosures);
            if (filtered.length) {
              for (const d of filtered) {
                disclosures.add(d);
              }
              filters.add(filter);
              shouldNotify = true;
            }
          }

          if (shouldNotify) {
            notifyNewDisclosure(Array.from(filters), Array.from(disclosures));
          }
        }
      );
      setSubscription(newSubscription);
    }
  }, [initialized, disclosureObservable, filterLibrary.filters]);

  return (
    <notificationContext.Provider value={{ enabled }}>
      {children}
    </notificationContext.Provider>
  );
};

export const useNotification = () => useContext(notificationContext);

const acquireNotificationPermission = () => {
  if (!('Notification' in window)) return;

  if (Notification.permission === 'granted') return;

  if (Notification.permission !== 'denied') {
    Notification.requestPermission();
  }
};
