import * as jaconv from 'jaconv';

export function normalizeStringForDisplay(s: string): string {
  return jaconv.normalize(s);
}

export function normalizeStringForSearch(s: string): string {
  return jaconv
    .toHebon(jaconv.toHiragana(jaconv.normalize(s)))
    .toLowerCase()
    .replace('-', '');
}

export function normalizeIssueCodeForSearch(s: string): string {
  // 1A2Cを１あ２ｃと打ってもキャッチできるように、jaconv.normalizeだけでなくtoHebonする
  return jaconv
    .toHebon(jaconv.toHiragana(jaconv.normalize(s)))
    .toUpperCase()
    .replace('-', '');
}
