import { createContext, useContext, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

export type RangeType = 'daily' | 'weekly' | 'flex';
export interface RangeContext {
  type: RangeType;
  day: string;
  dateStart: string;
  dateEnd: string;
  setType: (type: RangeType) => void;
  setDay: (day: string) => void;
  setDateStart: (dateStart: string) => void;
  setDateEnd: (dateEnd: string) => void;
}

const defaultValue: RangeContext = {
  type: 'daily',
  day: dayjs().format('YYYY-MM-DD'),
  dateStart: dayjs().format('YYYY-MM-DD'),
  dateEnd: dayjs().format('YYYY-MM-DD'),
  setType: () => undefined,
  setDay: () => undefined,
  setDateStart: () => undefined,
  setDateEnd: () => undefined,
};

const rangeContext = createContext<RangeContext>(defaultValue);

export const RangeProvider = ({ children }: { children: JSX.Element }) => {
  const [type, setType] = useState(defaultValue.type);
  const [day, setDay] = useState(defaultValue.day);
  const [dateStart, setDateStart] = useState(defaultValue.dateStart);
  const [dateEnd, setDateEnd] = useState(defaultValue.dateEnd);

  return (
    <rangeContext.Provider
      value={{
        type,
        day,
        dateStart,
        dateEnd,
        setType,
        setDay,
        setDateStart,
        setDateEnd,
      }}
    >
      {children}
    </rangeContext.Provider>
  );
};

export const useRange = () => useContext(rangeContext);
