export interface HeartbeatInterface {
  id: number;
  timestamp: Date;
}

export interface FindHeartbeatCondition {
  idGreaterThan: number;
}

export interface FindHeartbeatOption {
  count?: number;
  orderBy?: 'id';
  orderDirection?: 'ASC' | 'DESC';
}

export enum UpstreamStatus {
  HEALTHY = 'healthy',
  UNHEALTHY = 'unhealthy',
  UNKNOWN = 'unknown',
}

export type UpstreamStatusListener = (status: UpstreamStatus) => void;
