import {
  DisclosureCondition,
  FilterCondition,
  GlobalCondition,
  StockCondition,
} from './condition';
import {
  FullyJoinedDisclosure,
  GetDisclosuresRequest,
} from '@tedinet/data-access-disclosure';
import { createContext, useContext, useEffect, useState } from 'react';
import { useStocks } from './stock';
import { normalizeStringForSearch } from '@tedinet/util-string-normalizer';
import { industryCodeMap } from '@tedinet/data-access-stock';
import { DisclosureTag, useTags } from './tags';
import { StockInterface } from '@tedinet/data-access-stock';
import { useWatchlist, Watchlist } from './watchlist';

export type PartialCondition = {
  stock?: Partial<StockCondition>;
  disclosure?: Partial<DisclosureCondition>;
  global?: Partial<GlobalCondition>;
};

export type UpdatePartial = (diff: PartialCondition) => void;

export interface Filter {
  condition: FilterCondition;
  setCondition: (condition: FilterCondition) => void;
  setStockCondition: (condition: StockCondition) => void;
  setDisclosureCondition: (condition: DisclosureCondition) => void;
  setGlobalCondition: (condition: GlobalCondition) => void;
  updatePartial: UpdatePartial;
  disclosuresFilter: (
    disclosures: FullyJoinedDisclosure[]
  ) => FullyJoinedDisclosure[];
  todayDisclosuresFilter: (
    disclosures: FullyJoinedDisclosure[]
  ) => FullyJoinedDisclosure[];
  reset: () => void;
}

type SetPartialCondition = {
  (name: 'global'): (condition: GlobalCondition) => void;
  (name: 'stock'): (condition: StockCondition) => void;
  (name: 'disclosure'): (condition: DisclosureCondition) => void;
};

const InstantFilterContext = createContext<Filter | null>(null);

const validateString = (
  target: string,
  pattern1: string,
  pattern2?: string
): boolean => {
  if (pattern1 && target.indexOf(pattern1) > -1) return true;
  return pattern2 && target.indexOf(pattern2) > -1;
};

export const generateDisclosureFilter =
  (
    filter: FilterCondition,
    stocks: Record<number, StockInterface>,
    watchlists: Record<string, Watchlist>
  ) =>
  (disclosures: FullyJoinedDisclosure[]) => {
    const gKeyword =
      filter.global.keyword && normalizeStringForSearch(filter.global.keyword);
    const sKeyword =
      filter.stock.keyword && normalizeStringForSearch(filter.stock.keyword);
    const dKeyword =
      filter.disclosure.keyword &&
      normalizeStringForSearch(filter.disclosure.keyword);

    return disclosures.filter((d) => {
      if (d.issueCode.slice(-1) !== '0') return false;
      const issueCode = d.issueCode.toString().slice(0, 4);
      const stock = stocks[issueCode];
      if (!stock) return false;

      if (
        stock &&
        !validateMarketCapital(
          stocks[issueCode].marketCapital,
          filter.stock.marketCapital
        )
      )
        return false;

      const issueCodeForSearch = normalizeStringForSearch(issueCode);
      // keyword
      if (gKeyword) {
        if (
          issueCodeForSearch.indexOf(gKeyword) < 0 &&
          (!stock || !validateString(stock.nameForSearch, gKeyword)) &&
          !validateString(
            normalizeStringForSearch(d.latestDisclosureVersion.title),
            gKeyword
          )
        )
          return false;
      }
      if (sKeyword) {
        if (
          issueCodeForSearch.toString().indexOf(sKeyword) < 0 &&
          (!stock || !validateString(stock.nameForSearch, sKeyword))
        )
          return false;
      }
      if (dKeyword) {
        if (
          !validateString(
            normalizeStringForSearch(d.latestDisclosureVersion.title),
            dKeyword
          )
        )
          return false;
      }

      // issue codes
      if (filter.stock.issueCodes?.length) {
        if (!filter.stock.issueCodes.includes(issueCode))
          return false;
      }

      // industry
      if (!filter.stock.industry[stock?.industry33Code]) return false;

      // watchlist
      if (filter.stock.watchlists.length) {
        if (
          filter.stock.watchlists.every(
            (w) => !watchlists[w]?.issueCodes.includes(issueCode)
          )
        )
          return false;
      }

      // disclosure codes
      if (filter.disclosure.disclosureCodes?.length) {
        if (
          !filter.disclosure.disclosureCodes.includes(
            d.latestDisclosureVersion.disclosureCode
          )
        )
          return false;
      }

      // tags
      if (
        filter.disclosure.tagIDs.every(
          (ic) => !d.latestDisclosureVersion.tagIDs.includes(ic)
        )
      )
        return false;

      return true;
    });
  };

export const getInitialFilter: (
  tagChildren: DisclosureTag[],
  empty?: boolean
) => FilterCondition = (tagChildren, empty) => ({
  global: { keyword: '' },
  stock: {
    keyword: '',
    issueCodes: [],
    marketCapital: {
      v50: !empty,
      v50_100: !empty,
      v100_300: !empty,
      v300_1k: !empty,
      v1k_3k: !empty,
      v3k: !empty,
    },
    industry: Object.fromEntries(
      Object.keys(industryCodeMap).map((c) => [c, !empty])
    ),
    watchlists: [],
  },
  disclosure: {
    keyword: '',
    tagIDs: empty ? [] : tagChildren.map((t) => t.id),
    disclosureCodes: null,
  },
});

const validateMarketCapital = (
  marketCapital: number,
  condition: StockCondition['marketCapital']
): boolean => {
  if (marketCapital < 50 * 1e8) return condition.v50;
  if (marketCapital < 100 * 1e8) return condition.v50_100;
  if (marketCapital < 300 * 1e8) return condition.v100_300;
  if (marketCapital < 1000 * 1e8) return condition.v300_1k;
  if (marketCapital < 3000 * 1e8) return condition.v1k_3k;
  return condition.v3k;
};

export const useFilter = (empty?: boolean) => {
  const { dict: watchlistDict, watchlists } = useWatchlist();

  const {
    dict: stocks,
    todayDict: todayStocks,
    lastAvailableDict,
  } = useStocks();
  const stockDict = Object.assign(
    { ...stocks },
    todayStocks,
    lastAvailableDict
  );
  const { children: tags } = useTags();

  const initialState: FilterCondition = getInitialFilter(tags, empty);

  const [state, setState] = useState<FilterCondition>(initialState);
  const [todayDisclosuresFilter, setTodayDisclosuresFilter] = useState(() =>
    generateDisclosureFilter(state, todayStocks, watchlistDict)
  );

  useEffect(() => {
    const removedWatchlistIDs: number[] = [];
    for (const watchlistID of state.stock.watchlists) {
      if (watchlists.every((w) => w.id !== watchlistID)) {
        removedWatchlistIDs.push(watchlistID);
      }
    }
    if (removedWatchlistIDs.length) {
      universalSetPartialCondition({
        stock: {
          watchlists: state.stock.watchlists.filter(
            (id) => !removedWatchlistIDs.includes(id)
          ),
        },
      });
    }
  }, [watchlistDict]);

  const setPartialCondition: SetPartialCondition =
    (name: any) => (condition: any) => {
      switch (name) {
        case 'global':
          setState((prev) => ({
            ...prev,
            global: condition,
          }));
          break;
        case 'stock':
          setState((prev) => ({
            ...prev,
            stock: condition,
          }));
          break;
        case 'disclosure':
          setState((prev) => ({
            ...prev,
            disclosure: condition,
          }));
          break;
      }
    };

  const universalSetPartialCondition: UpdatePartial = (
    diff: PartialCondition
  ) => {
    setState((prev) => {
      if (diff.stock) {
        Object.assign(prev.stock, diff.stock);
      }
      if (diff.disclosure) {
        Object.assign(prev.disclosure, diff.disclosure);
      }
      if (diff.global) {
        Object.assign(prev.global, diff.global);
      }

      return { ...prev };
    });
  };

  const reset = () => setState(initialState);

  return {
    condition: state,
    setCondition: setState,
    setGlobalCondition: setPartialCondition('global'),
    setStockCondition: setPartialCondition('stock'),
    setDisclosureCondition: setPartialCondition('disclosure'),
    updatePartial: (diff) => universalSetPartialCondition(diff),
    disclosuresFilter: generateDisclosureFilter(
      state,
      stockDict,
      watchlistDict
    ),
    todayDisclosuresFilter,
    reset,
  };
};

export const InstantFilterProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const localStorageKey = 'tedinet-instant-filter';
  const filter = useFilter();

  // 起動時に保存内容をlocal storageから復元
  useEffect(() => {
    const saved = localStorage.getItem(localStorageKey);
    if (saved) {
      const parsed: FilterCondition = JSON.parse(saved);

      // 2022-06-24
      // Replace v100 with v50 and v50_100
      if ('v100' in parsed.stock.marketCapital) {
        if (parsed.stock.marketCapital['v100']) {
          parsed.stock.marketCapital.v50 = true;
          parsed.stock.marketCapital.v50_100 = true;
        } else {
          parsed.stock.marketCapital.v50 = false;
          parsed.stock.marketCapital.v50_100 = false;
        }
        delete parsed.stock.marketCapital['v100'];
      }
      // 2022-06-27
      // Replace v100_1k with v100_300 and v300_1k
      if ('v100_1k' in parsed.stock.marketCapital) {
        if (parsed.stock.marketCapital['v100_1k']) {
          parsed.stock.marketCapital.v100_300 = true;
          parsed.stock.marketCapital.v300_1k = true;
        } else {
          parsed.stock.marketCapital.v100_300 = false;
          parsed.stock.marketCapital.v300_1k = false;
        }
        delete parsed.stock.marketCapital['v100_1k'];
      }

      filter.setCondition(parsed);
    }
  }, []);

  // 変更をlocal storageに保存
  useEffect(() => {
    const conditionInString = JSON.stringify(filter.condition);
    localStorage.setItem(localStorageKey, conditionInString);
  }, [filter.condition]);

  return (
    <InstantFilterContext.Provider value={filter}>
      {children}
    </InstantFilterContext.Provider>
  );
};

export const useInstantFilter = () => useContext(InstantFilterContext);

export const conditionToSearchParam = (
  condition: FilterCondition,
  stocks: StockInterface[],
  watchlists: Record<string, Watchlist>
): GetDisclosuresRequest => {
  const issueCodes = new Set(condition.stock.issueCodes);
  for (const stock of stocks) {
    if (
      validateMarketCapital(stock.marketCapital, condition.stock.marketCapital)
    ) {
      issueCodes.add(stock.issueCode);
    } else if (condition.stock.industry[stock.industry33Code]) {
      issueCodes.add(stock.issueCode);
    }
  }
  for (const watchlist of condition.stock.watchlists) {
    watchlists[watchlist].issueCodes.forEach((ic) => issueCodes.add(ic));
  }

  return {
    issueCodes: Array.from(issueCodes).map((i) => i + '0'),
    disclosureTags: condition.disclosure.tagIDs,
    disclosureTitle: condition.disclosure.keyword || undefined,
  };
};

export const useDisclosureFilterGenerator = () => {
  const { dict: watchlists } = useWatchlist();
  const { dict: stocks, lastAvailableDict } = useStocks();
  const stockDict = Object.assign({ ...lastAvailableDict }, stocks);

  return (filter) => generateDisclosureFilter(filter, stockDict, watchlists);
};
