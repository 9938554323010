// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN)
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://1c70bedf96d145dc9cd3c7a10e4ad7bc@o114713.ingest.sentry.io/6292585',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded',
    ],
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
