import { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';

export interface Watchlist {
  id: number;
  name: string;
  issueCodes: string[];
  referencedFrom: number[];
}

type UseWatchlistReturn = {
  watchlists: Watchlist[];
  dict: Record<number, Watchlist>;
  updateWatchlists: (watchlists: Watchlist[]) => void;
  updateWatchlist: (watchlist: Watchlist) => void;
  removeWatchlist: (watchlist: Watchlist) => boolean;
  newReference: (from: number, watchlistIDs: number[]) => void;
  removeReference: (from: number, watchlistID: number) => void;
};

export function createWatchlist(name: string): Watchlist {
  return {
    id: new Date().getTime(),
    name,
    issueCodes: [],
    referencedFrom: [],
  };
}

const WatchlistContext = createContext<UseWatchlistReturn | null>(null);

export const WatchlistProvider = ({ children }: { children: JSX.Element }) => {
  const [watchlists, _setWatchlists] = useState<Watchlist[]>([]);
  const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const setWatchlists = (watchlists: Watchlist[]) => {
    const data = watchlists.map((w) => {
      return {
        ...w,
        issueCodes: Array.from(new Set(w.issueCodes)),
      };
    });
    _setWatchlists(data);
  };

  const dict = Object.fromEntries(watchlists.map((w) => [w.id, w]));

  const setWatchlist = (watchlist: Watchlist) => {
    dict[watchlist.id] = watchlist;
    setWatchlists(Object.values(dict));
  };
  const deleteWatchlist = (watchlist: Watchlist): boolean => {
    if (dict[watchlist.id].referencedFrom.length) {
      enqueueSnackbar(
        `検索条件内で使用されている銘柄リストは削除できません。`,
        { variant: 'warning' }
      );
      return false;
    }
    delete dict[watchlist.id];
    setWatchlists(Object.values(dict));
    return true;
  };

  const newReference = (from: number, watchlistIDs: number[]) => {
    for (const watchlistID of watchlistIDs) {
      if (dict[watchlistID])
        dict[watchlistID].referencedFrom = Array.from(
          new Set([...dict[watchlistID].referencedFrom, from])
        );
    }
    setWatchlists(Object.values(dict));
  };

  const removeReference = (from: number, watchlistID: number) => {
    if (dict[watchlistID])
      dict[watchlistID].referencedFrom = dict[
        watchlistID
      ].referencedFrom.filter((f) => f !== from);
    setWatchlists(Object.values(dict));
  };

  useEffect(() => {
    const savedWatchlists = localStorage.getItem('tedinet-watchlist');
    setLocalStorageLoaded(true);
    if (!savedWatchlists) {
      setWatchlists([
        {
          id: 1,
          name: '日経高寄与度',
          issueCodes: ['9983', '8035', '6857', '9984'],
          referencedFrom: [],
        },
      ]);
    } else {
      const loadedWatchlists = JSON.parse(savedWatchlists);
      for (const k in loadedWatchlists) {
        if (loadedWatchlists[k] && !loadedWatchlists[k].id) {
          loadedWatchlists[k].id = new Date().getTime();
        }
        if (loadedWatchlists[k] && !loadedWatchlists[k].referencedFrom) {
          loadedWatchlists[k].referencedFrom = [];
        }
        if (
          loadedWatchlists[k] &&
          (loadedWatchlists[k].name === null ||
            loadedWatchlists[k].name === undefined)
        ) {
          loadedWatchlists[k].name = '(銘柄リスト名取得失敗)';
        }
        if (loadedWatchlists[k] && !loadedWatchlists[k].issueCodes) {
          loadedWatchlists[k].issueCodes = [];
        }
        loadedWatchlists[k].issueCodes = loadedWatchlists[k].issueCodes.map(c => c.toString());
      }
      setWatchlists(loadedWatchlists);
    }
  }, []);

  useEffect(() => {
    if (localStorageLoaded)
      localStorage.setItem('tedinet-watchlist', JSON.stringify(watchlists));
  }, [watchlists]);

  return (
    <WatchlistContext.Provider
      value={{
        watchlists,
        updateWatchlists: setWatchlists,
        updateWatchlist: setWatchlist,
        dict,
        removeWatchlist: deleteWatchlist,
        newReference,
        removeReference,
      }}
    >
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlist = () => useContext(WatchlistContext);
