import { createContext, useContext, useEffect, useState } from 'react';
import { StockInterface } from '@tedinet/data-access-stock';
import { useServerStatus } from './server-status';
import { useRange } from './range';
import { useAPI } from './use-api';

export interface StockContext {
  list: StockInterface[];
  dict: Record<number, StockInterface>;
  todayList: StockInterface[];
  todayDict: Record<number, StockInterface>;
  lastAvailableList: StockInterface[];
  lastAvailableDict: Record<number, StockInterface>;
  lastAvailableDate: string;
}

const stockContext = createContext<StockContext>({
  list: [],
  dict: {},
  todayList: [],
  todayDict: {},
  lastAvailableList: [],
  lastAvailableDict: {},
  lastAvailableDate: '',
});

export const StockProvider = ({ children }: { children: JSX.Element }) => {
  const serverStatus = useServerStatus();
  const { dateStart, dateEnd } = useRange();

  const [state, setState] = useState<StockContext>({
    list: [],
    dict: {},
    todayList: [],
    todayDict: {},
    lastAvailableList: [],
    lastAvailableDict: {},
    lastAvailableDate: '',
  });
  const [updatedForStart, setUpdatedForStart] = useState(false);

  const { getStocks, getLastAvailableStocks } = useAPI();

  useEffect(() => {
    console.log('stock server status changed');
    getLastAvailableStocks().then(([stocks, lastAvailableDate]) => {
      setState((p) => ({
        ...p,
        lastAvailableList: stocks,
        lastAvailableDict: Object.fromEntries(
          stocks.map((s) => [s.issueCode, s])
        ),
        lastAvailableDate,
      }));
    });
    if (serverStatus?.date) {
      getStocks({ date: serverStatus.date }).then((stocks) => {
        setState((p) => ({
          ...p,
          todayList: stocks,
          todayDict: Object.fromEntries(stocks.map((s) => [s.issueCode, s])),
        }));
      });
    }
  }, [serverStatus?.date]);

  useEffect(() => {
    if (dateStart === serverStatus?.date) {
      setState((p) => ({ ...p, list: p.todayList, dict: p.todayDict }));
      setUpdatedForStart(true);
    } else {
      getStocks({ date: dateStart }).then((stocks) => {
        setState((p) => ({
          ...p,
          list: stocks,
          dict: Object.fromEntries(stocks.map((s) => [s.issueCode, s])),
        }));
        setUpdatedForStart(true);
      });
    }
  }, [dateStart]);

  useEffect(() => {
    if (!updatedForStart || dateStart === dateEnd) return;
    if (dateEnd >= serverStatus?.date)
      setState((p) => {
        p.todayList.forEach((s) => {
          if (s.issueCode in p.dict) p.dict[s.issueCode] = s;
          else {
            p.dict[s.issueCode] = s;
            p.list.push(s);
          }
        });
        return {
          ...p,
        };
      });
    else
      getStocks({ date: dateEnd }).then((stocks) => {
        setState((p) => {
          stocks.forEach((s) => {
            if (s.issueCode in p.dict) p.dict[s.issueCode] = s;
            else {
              p.dict[s.issueCode] = s;
              p.list.push(s);
            }
          });
          return {
            ...p,
          };
        });
      });
  }, [updatedForStart]);

  useEffect(() => {
    setUpdatedForStart(false);
  }, [dateStart, dateEnd]);

  return (
    <stockContext.Provider value={state}>{children}</stockContext.Provider>
  );
};

export const useStocks = () => useContext(stockContext);
