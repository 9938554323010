// master -> ファイルの更新検知とboth, api, wsへの配信のみを行う
// both -> masterから配信を受け取りながら、APIとWSのサーバとして動く
// api -> masterから配信を受け取りながら、APIのサーバとして動く
// ws -> masterから配信を受け取りながら、WSのサーバとして動く
// standalone -> ファイルの更新検知を行いながら、APIとWSのサーバとして動く。both, api, wsへの配信は行わない
export enum InstanceRole {
  MASTER = 'master',
  WS = 'ws',
  API = 'api',
  BOTH = 'both',
  STANDALONE = 'standalone',
}

export function apiEnabled(role: InstanceRole): boolean {
  return [
    InstanceRole.API,
    InstanceRole.BOTH,
    InstanceRole.STANDALONE,
  ].includes(role);
}

export function wsEnabled(role: InstanceRole): boolean {
  return [InstanceRole.WS, InstanceRole.BOTH, InstanceRole.STANDALONE].includes(
    role
  );
}
